<template>
	<vue-good-table
    @on-selected-rows-change="selectionChanged"
    :columns="columns"
    :rows="eventAssignments"
    styleClass="vgt-table bordered condensed"
    :pagination-options="{
      enabled: true,
      mode: 'records',
      perPage: 25,
    }"
    :search-options="{
      enabled: true,
      placeholder: 'Search this table',
    }"
    :sort-options="{
      enabled: true,
      initialSortBy: {field: 'firstName', type: 'asc'}
    }"
  >

    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field == 'regRate'">
        <input type="number" v-model.trim="props.row.regRate" id="regRate" @blur="onEditRegRate(props.row)" :readonly="props.row.locked" />
      </span>
      <span v-else-if="props.column.field == 'dayRate'">
        <input type="text" v-model.trim="props.row.dayRate" id="dayRate" @blur="onEditDayRate(props.row)" :readonly="props.row.locked" />
      </span>
      <span v-else-if="props.column.field == 'firstName'">
        <input type="text" v-model.trim="props.row.firstName" id="firstName" readonly />
      </span>
      <span v-else-if="props.column.field == 'lastName'">
        <input type="text" v-model.trim="props.row.lastName" id="lastName" readonly />
      </span>

      <span v-else-if="props.column.field == 'note'">
        <button v-show="!props.row.note" class="btn btn__flat btn__icon" @click="showNote(props.row)" v-tooltip="'Leave a note'"><i class="far fa-sticky-note ml-3 mr-3" style="opacity:0.5;"></i></button>
        <button v-show="props.row.note" class="btn btn__flat btn__icon" @click="showNote(props.row)" v-tooltip="'Leave a note'"><i class="far fa-sticky-note ml-3 mr-3" style="color:blue"></i></button>
        <transition name="modal">
          <div v-if="activeItem == props.row">
            <TimesheetNote :item="props.row" @close="closeNote(props.row)" />
          </div>
        </transition>
      </span>

      <span v-else-if="props.column.field == 'checkInTimeStamp'">
        <span v-if="props.row.checkInTimeStamp">{{formatDate(props.row.checkInTimeStamp)}}</span>

        <input v-if="!props.row.checkInTimeStamp" type="time" v-model.trim="props.row.inTime" id="in" @blur="onEditInOut(props.row)" readonly />

      </span>

      <span v-else-if="props.column.field == 'checkOutTimeStamp'">
        <span v-if="props.row.checkOutTimeStamp">{{formatDate(props.row.checkOutTimeStamp)}}</span>

        <input v-if="!props.row.checkOutTimeStamp" type="time" v-model.trim="props.row.outTime" id="in" @blur="onEditInOut(props.row)" readonly />

      </span>
      <span v-else-if="props.column.field == 'date'">
        <span v-if="props.row.date">{{props.row.date}}</span>
      </span>

      <span v-else-if="props.column.field == 'position'">
        <span v-if="props.row.position">{{props.row.position}}</span>
      </span>

      <span v-else-if="props.column.field == 'confirmed'">
        <span v-if="props.row.confirmed">{{props.row.confirmed}}</span>
      </span>

      <span v-else-if="props.column.field == 'breakTime'">
        <input type="number" v-model.trim="props.row.breakTime" id="breakTime" @blur="onEditBreakTime(props.row)" readonly />
      </span>

      <span v-else-if="props.column.field == 'totalHours'">
        <input type="number" v-model.trim="props.row.totalHours" id="totalHours" @blur="onEditTotalHours(props.row)" readonly />
      </span>

      <span v-else-if="props.column.field == 'regHours'">
        <input type="number" v-model.trim="props.row.regHours" id="regHours" @blur="onEditRegHours(props.row)" readonly />
      </span>
      <span v-else-if="props.column.field == 'otHours'">
        <input type="number" v-model.trim="props.row.otHours" id="otHours" @blur="onEditotHours(props.row)" readonly />
      </span>
      <span v-else-if="props.column.field == 'ot2Hours'">
        <input type="number" v-model.trim="props.row.ot2Hours" id="ot2Hours" @blur="onEditot2Hours(props.row)" readonly />
      </span>
      <span v-else-if="props.column.field == 'mbp'">
        <input type="number" v-model.trim="props.row.mbp" id="mbp" @blur="onEditMBP(props.row)" readonly />
      </span>
      <span v-else-if="props.column.field == 'tips'">
        <input type="number" v-model.trim="props.row.tips" id="tips" @blur="onEditTips(props.row)" readonly  />
      </span>
      <span v-else-if="props.column.field == 'state'">
        <input type="text" v-model.trim="props.row.state" placeholder="CA" id="state" @change="onSheetEditable(props.row)" readonly />
      </span>
      <span v-else-if="props.column.field == 'status'">
        <v-select
          label="status" 
          :options="statuses"
          v-model="props.row.status"
          @input="onUpdateStatus(props.row)"
          >
        </v-select>
      </span>
      <span v-else-if="props.column.field == 'paystatus'">
        <v-select
          label="status" 
          :options="paystatuses"
          v-model="props.row.paystatus"
          @input="onUpdatePay(props.row)"
          >
        </v-select>
      </span>
      <span v-else-if="props.column.field == 'locked'">
        <button class="btn btn__outlined btn__small" @click="lock(props.row)" v-if="!props.row.locked">
          Lock
          <i class="fas fa-lock-open-alt ml-2"></i>
        </button>
        <button class="btn btn__outlined btn__small" @click="unlock(props.row)" v-if="props.row.locked">
          Unlock
          <i class="fas fa-lock-alt ml-2" style="color:#5cb85c;"></i>
        </button>
      </span>
      <span v-if="props.column.field == 'fileId'">
        <span v-if="props.row.fileId">HAS SSN</span>
      </span>


      <span v-else-if="(props.column.field == 'delete') && hidden">
          <button class="btn btn__primary btn__small ml-2 mr-2" @click="showEntry(props.row)">
            show
          </button>
        </span>


      
      <span v-else-if="props.column.field == 'link'">
        <router-link :to="`/users/` + props.row.userId" target="_blank">
          <i class="fas fa-external-link ml-3 mr-3"></i>
        </router-link>
      </span>
      <span v-else-if="props.column.field == 'payTotal'">
        <input type="text" v-model.trim="props.row.payTotal" id="payTotal" readonly />
      </span>
      <!-- <span v-else-if="props.column.field == 'save'">
        <span v-for="(u, index) in filteredInfo(props.row)" class="flex" :key="index">
          <span v-if="(u.evereeOnboardingComplete && (index == '0'))">
            <button :disabled="(!props.row.payTotal || props.row.payTotal == null)" class="btn btn__primary btn__small ml-2 mr-2" @click="sendPayment(props.row)">
            Send Payment
            </button>
          </span>
        </span>
      </span> -->
       <span v-else>
        <!-- {{props.formattedRow[props.column.field]}} -->
      </span>
    </template>
  </vue-good-table>
</template>

<style scoped>
  .vgt-selection-info-row {
    height: 3.28rem;
  }
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
import TimesheetNote from '@/components/Timesheets/TimesheetNote.vue'
import firebase from 'firebase/app';
import * as moment from 'moment'
const fb = require('../firebaseConfig.js')

export default {
	props: ['eventInfo', 'eventAssignments', 'hidden', 'type'],
  data: () => ({
    performingRequest: false,
    activeItem: null,
    activeDay: '',
    batch: [],
    statuses: ['completed', 'no-show', 'dropped', 'no-response', 'arrived late', 'left early', 'client fired', 'terminated' ],
    paystatuses: ['paid', 'paid tips', 'paid hours', 'not paid' ],
    columns: [
      {
        field: 'delete',
        sortable: false,
      },
      {
        label: 'Date',
        field: 'date',
      },
      // {
      //   label: 'Confirmed',
      //   field: 'confirmed',
      //   sortable: false,
      // },
      {
        label: 'Pay Status',
        field: 'paystatus',
        width: '152px',
        sortable: false,
      },
      {
        label: 'Event Status',
        field: 'status',
        width: '152px',
        sortable: false,
      },
      {
        field: 'note',
        sortable: false,
      },
      {
        label: 'First',
        field: 'firstName',
        width: '100px',
      },
      {
        label: 'Last',
        field: 'lastName',
        width: '100px',
      },
      {
        label: 'Link',
        field: 'link',
        sortable: false,
      },
      {
        label: 'Shift',
        field: 'position',
      },
      
      {
        label: 'Rate',
        field: 'regRate',
        width: '64px',
        sortable: false,
      },
      {
        label: 'In',
        field: 'checkInTimeStamp',
        width: '88px',
        sortable: false,
      },
      {
        label: 'Out',
        field: 'checkOutTimeStamp',
        width: '88px',
        sortable: false,
      },
      
      {
        label: 'Total Hours',
        field: 'totalHours',
        width: '88px',
        sortable: false,
      },
      {
        label: 'Break Time',
        field: 'breakTime',
        width: '88px',
        sortable: false,
      },
      {
        label: 'Reg Hours',
        field: 'regHours',
        width: '88px',
        sortable: false,
      },
      {
        label: 'OT',
        field: 'otHours',
        width: '88px',
        sortable: false,
      },
      {
        label: '2OT',
        field: 'ot2Hours',
        width: '88px',
        sortable: false,
      },
      {
        label: 'MBP Penalty',
        field: 'mbp',
        width: '88px',
        sortable: false,
      },
      {
        label: 'Tips',
        field: 'tips',
        width: '88px',
      },
      {
        label: 'Bonus',
        field: 'dayRate',
        width: '88px',
        sortable: false,
      },
      {
        label: 'Pay Total',
        field: 'payTotal',
        width: '88px',
        sortable: false,
      },
    ],
  }),
  computed: {
  	// visibleAssignments() {
    //   return this.eventAssignments.filter(item => {
    //     return ((!item.hidden || item.hidden != true) && (!item.paystatus || item.paystatus != 'paid'))
    //   })
    // },
    // hiddenAssignments() {
    //   return this.eventAssignments.filter(item => {
    //     return (item.hidden)
    //   })
    // },
    // paidAssignments() {
    //   return this.eventAssignments.filter(item => {
    //     return (item.paystatus == 'paid')
    //   })
    // },
  },
  components: {
    Loader,
    TimesheetNote
  },
  methods: {
    selectionChanged(params) {
      this.batch = params.selectedRows
    },
    payBatch() {
      this.performingRequest = true
      this.batch.forEach(item => {
        const sendUserPayment = firebase.functions().httpsCallable('createBatchPayment')
          sendUserPayment({
          amount: item.payTotal,
          id: item.userId,
          note: item.name  
        })
        .then(result => {
          if (result && result.data) {
            console.log(result.data)
            fb.assignmentsCollection.doc(item.id).update({
              paystatus: 'paid',
              returned: result.data
            })
          }
        })
      })
      this.performingRequest = false
    },
    async sendPayment(row) {
      console.log(row)
      const sendUserPayment = firebase.functions().httpsCallable('createBatchPayment')
        await sendUserPayment({
        amount: row.payTotal,
        id: row.userId,
        note: row.name  
      })
      .then(result => {
        if (result && result.data) {
          console.log(result.data)
          fb.assignmentsCollection.doc(row.id).update({
            paystatus: 'paid',
            returned: result.data
          })
        }
      })
    },

    // filteredInfo(user) {
    //   return this.eventUsersRef.filter(member => {
    //     if (member && member.id) {
    //       return (member.id == user.userId)
    //     }
    //   })
    // },
    goBack() {
      router.go(-1)
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;

        return moment.utc(postedDate).local().format('HH:mm:ss A')
        // return moment(postedDate).format('HH:MM A')
      } else {
        return null
      }
    },
    formatAMPM(date) {
      if (typeof date === "string") {
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          ampm = "PM";
        }

        return `${hours}:${minutes} ${ampm}`;

      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;

        return strTime;
      }

      return date;
    },
    async onUpdatePay(row) {
      row.editable = true
      await this.$store.dispatch('updateTimesheetPay', {
        id: row.id,
        paystatus: row.paystatus
      })
      row.editable = false
    },
    async onUpdateStatus(row) {
      row.editable = true
      await this.$store.dispatch('updateTimesheetStatus', {
        id: row.id,
        status: row.status
      })
      row.editable = false
    },
    showNote(r) {
      console.log(r)
      this.activeItem = r
    },
    closeNote(r) {
      this.activeItem = null
    },
    removeEntry(row) {
      fb.assignmentsCollection.doc(row.id).update({
        hidden:true
      })
    },
    showEntry(row) {
      fb.assignmentsCollection.doc(row.id).update({
        hidden:false
      })
    },
    onEditRegRate(row) {
      console.log('updating reg rate')
      row.editable = true
      fb.assignmentsCollection.doc(row.id).update({
        regRate: row.regRate
      })
      this.onUpdateTotal(row)
      row.editable = false
    },
    onEditInOut(row) {
      console.log('updating in out')
      row.editable = true
      if (row.inTime && row.outTime) {
        let inTime = row.inTime || null
        var inhoursMinutes = inTime.split(/[.:]/);
        var inhours = parseInt(inhoursMinutes[0], 10);
        var inminutes = inhoursMinutes[1] ? parseInt(inhoursMinutes[1], 10) : 0;
        let outTime = row.outTime || null
        var outhoursMinutes = outTime.split(/[.:]/);
        var outhours = parseInt(outhoursMinutes[0], 10);
        var outminutes = outhoursMinutes[1] ? parseInt(outhoursMinutes[1], 10) : 0;
        let totalHours = ((outhours + outminutes / 60).toFixed(2) - (inhours + inminutes / 60).toFixed(2))
        row.inTime = inTime
        row.outTime = outTime
        row.totalHours = totalHours.toFixed(2)

        fb.assignmentsCollection.doc(row.id).update({
          inTime: inTime,
          outTime: outTime,
          totalHours: totalHours
        })
        row.editable = false
        this.onEditTotalHours(row)
      } else {
        
      }
    },
     onEditTotalHours(row) {
      console.log('updating total hours')
      if (row.breakTime) {
      fb.assignmentsCollection.doc(row.id).update({
        totalHours: row.totalHours
      })
      } else {
        
      }
      this.onEditRegHours(row)
    },
     onEditRegHours(row) {
      console.log('updating reg hours')
      let calcHours = (row.totalHours - (row.breakTime || 0))
      fb.assignmentsCollection.doc(row.id).update({
        regHours: calcHours
      })
      row.regHours = calcHours
      this.onUpdateTotal(row)
    },
     onEditotHours(row) {
      console.log('updating ot hours')
      if (row.otHours) {
        fb.assignmentsCollection.doc(row.id).update({
          otHours: row.otHours
        })
        this.onUpdateTotal(row)
      } else {
        
      }
    },
    onEditot2Hours(row) {
      console.log('updating 2ot rate')
      if (row.ot2Hours) {
      fb.assignmentsCollection.doc(row.id).update({
        ot2Hours: row.ot2Hours
      })
      this.onUpdateTotal(row)
      } else {
       
      }
    },
    onEditDayRate(row) {
      console.log('updating day rate')
      if (row.dayRate) {
      fb.assignmentsCollection.doc(row.id).update({
        dayRate: row.dayRate
      })
      this.onUpdateTotal(row)
      } else {
       
      }
    },
    onEditBreakTime(row) {
      console.log('updating break')
      if (row.breakTime) {
      fb.assignmentsCollection.doc(row.id).update({
        breakTime: row.breakTime
      })
      this.onEditRegHours(row)
      } else {
        
      }
    },
    onUpdateTotal(row) {
      console.log('editing total')
      console.log(row)

      if (row.regHours && row.regRate) {

      let total = (row.regHours * row.regRate) + 
      (parseFloat((row.otHours || 0) * (row.regRate * 1.5))) + 
      (parseFloat((row.ot2Hours || 0) * ((row.regRate || 0) * 2))) + 
      (parseFloat((row.mbp || 0) * row.regRate)) + 
      (parseFloat(row.tips || 0)) + 
      (parseFloat(row.dayRate || 0))
      console.log(total)
      fb.assignmentsCollection.doc(row.id).update({
        payTotal: total.toFixed(2),
        paytimestamp: new Date().getTime()
      })
      } else {
        
      }
    },
    onEditMBP(row) {
      console.log('updating mbp')
      if (row.mbp) {
      fb.assignmentsCollection.doc(row.id).update({
        mbp: row.mbp
      })
      this.onUpdateTotal(row)
      } else {
        
      }
    },
    onEditTips(row) {
      console.log('updating tips')
      if (row.tips) {
      fb.assignmentsCollection.doc(row.id).update({
        tips: row.tips
      })
      this.onUpdateTotal(row)
      } else {
        
      }
    },
    onSheetEditable(row)  {
      row.editable = true
    },
    onSheetEdit(row) {
      row = row
      row.editable = false
      this.$store.dispatch('updateTimesheet', row)
    },
  },
  beforeDestroy () {
    this.columns = null
    delete this.columns
    this.activeItem = null
    delete this.activeItem
    this.activeDay = null
    delete this.activeDay
    this.batch = null
    delete this.batch
    this.statuses = null
    delete this.statuses
    this.paystatuses = null
    delete this.paystatuses
    delete this.onSheetEdit
    delete this.onSheetEditable
    delete this.sendEmployeePayment
    delete this.sendPayment
    delete this.payBatch
    delete this.onUpdateTotal
    delete this.onEditTips
    delete this.onEditMBP
    delete this.onEditBreakTime
    delete this.onEditDayRate
    delete this.onEditot2Hours
    delete this.onEditotHours
    delete this.onEditMinHours
    delete this.onManEditRegHours
    delete this.onEditRegHours
    delete this.onEditTotalHours
    delete this.onEditIn
    delete this.onEditOut
    delete this.onEditRegRate
    delete this.onEditPosition
    delete this.showEntry
    delete this.removeEntry
    delete this.showNote
    delete this.closeNote
    delete this.onUpdateStatus
    delete this.onUpdatePay
    delete this.formatAMPM
    delete this.formatDate
    delete this.filteredInfo
    delete this.filteredEmployee
    delete this.selectionChanged
    delete this.showEditIn
    delete this.showEditOut
    delete this.removeIn
    delete this.removeOut
  }
}
</script>